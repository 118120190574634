/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";

import "./styles.scss";

import { NavLink, useSearchParams } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import { getExtratoSimples } from "services/extrato.service";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt-BR";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import "moment/locale/pt-br";
import { RegulamentoFooter } from "components/regulamento-footer";

moment.locale("pt-br");

export function Extrato() {
  const { user } = useAuth();
  const { showModal } = useModal();
  const [extrato, setExtrato] = useState<any>();
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const { register, handleSubmit, setValue, reset, control } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [extratoPlayer, setExtratoPlayer] = useState<boolean>(true);
  let [searchParams, setSearchParams] = useSearchParams();

  async function loadData(id: any) {
    setLoading(true);
    const data = await getExtratoSimples(
      id,
      startDate ? startDate : "2023-01-01T00:00:00.000Z",
      endDate ? endDate : "NOW"
    );
    setExtrato(data);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    if (user) {
      // console.log(user.challenges);
      const completed = user.extra.avatar || user.image;
      if (!completed) {
        showModal("avatar-challenge");
      }

      if (searchParams.get("unidade")) {
        setTimeout(() => {
          setExtratoPlayer(false);
          var btn = document.getElementById("extratoUnidade");
          btn?.click();
        }, 500);
      } else {
        loadData(user._id);
      }
    }
  }, [user]);

  useEffect(() => {
    if (endDate) {
      endDate.setHours(20, 59, 59); //ajustar depois
      setEndDate(endDate);
    }
    if (user) {
      extratoPlayer ? loadData(user?._id) : loadData(user?.extra.unidade);
    }
  }, [user, startDate, endDate]);

  registerLocale("pt-BR", pt);

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>ACELERA BRB 2025</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:p-10">
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>
          <div
            className="w-full md:w-[1200px] p-4 md:p-8 md:flex"
            id="filterExtrato"
          >
            <div className="md:w-[20%]">
              <h2>
                <strong>Extrato</strong>
              </h2>
            </div>
            <div className="md:w-[80%] flex justify-end filterDate">
              <form
                id="search-form-theme-calendar"
                autoComplete="off"
                noValidate
              >
                <div>
                  <Controller
                    name="startTime"
                    control={control}
                    render={() => (
                      <DatePicker
                        placeholderText="Data Inicio"
                        className="input md:mr-2 w-full md:w-auto"
                        locale="pt-BR"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(e: any) => {
                          setStartDate(e);
                          setValue("startTime", new Date(e as Date).getTime());
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="endTime"
                    control={control}
                    render={() => (
                      <DatePicker
                        placeholderText="Data Fim"
                        className="input md:mr-2 w-full md:w-auto"
                        locale="pt-BR"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(e: any) => {
                          setEndDate(e);
                          setValue("endTime", new Date(e as Date).getTime());
                        }}
                      />
                    )}
                  />
                </div>
                <div className="flex justify-center items-center">
                  <button
                    type="button"
                    onClick={() => {
                      reset();
                      setStartDate("");
                      setEndDate("");
                    }}
                  >
                    Limpar
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="w-full md:w-[1200px] p-4 md:p-8 md:flex filter">
            <button
              id="meuExtrato"
              className={
                "bg-[#6b6b85] text-[#04227C]  p-3 font-bold uppercase" +
                (extratoPlayer ? " active" : "")
              }
              onClick={() => {
                loadData(user._id);
                setExtratoPlayer(true);
              }}
            >
              Meu Extrato
            </button>
            <button
              id="extratoUnidade"
              className={
                "bg-[#6b6b85] text-[#04227C]  p-3 font-bold uppercase ml-2" +
                (extratoPlayer ? "" : " active")
              }
              onClick={() => {
                loadData(user.extra.unidade);
                setExtratoPlayer(false);
              }}
            >
              Extrato Unidade
            </button>
          </div>
          <div className="w-full md:w-[1200px]" id="extrato">
            {loading ? (
              <p className="text-center loading">Carregando...</p>
            ) : (
              <Scrollbars
                style={{ width: "100%", height: 400 }}
                className="contentExtrato"
                renderTrackVertical={(props) => (
                  <div {...props} className="track-vertical" />
                )}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th className="w-[120px] rounded-l-md md:rounded-none">
                        DATA
                      </th>
                      <th>QUANTIDADE</th>
                      <th>DESCRIÇÃO</th>
                      <th>DESAFIO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {extrato
                      ?.filter((item: any) => item.type !== 1)
                      .map((item: any) => (
                        <tr key={item._id}>
                          <td>
                            <span className="hidden md:block">
                              {moment(item.time).format("DD/MM/YYYY HH:mm")}
                            </span>
                            <span className="md:hidden block w-16">
                              {moment(item.time).format("DD/MM YYYY HH:mm")}
                            </span>
                          </td>
                          <td>{!!item.total ? item.total : 0}</td>
                          <td>{item.item.name}</td>
                          <td>{item.origin.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Scrollbars>
            )}
          </div>
        </div>
        <RegulamentoFooter />
      </div>
      <Outlet />
    </>
  );
}

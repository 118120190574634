import { NavMenu } from 'components/navmenu';
import { UserInfo } from 'components/user-info';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';
import Logo from 'assets/img/logo.svg';
import { useAuth } from 'context/AuthContext';
import { whiteBox } from 'styles';

export default function Terms() {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return (
      <>
        <Helmet>
          <title>Regulamento - ACELERA BRB 2025</title>
        </Helmet>
        <div id="home-wrapper" className="min-h-screen">
          <div className="z-10 relative flex flex-col items-center space-y-8 md:space-y-16 md:p-10">
            <UserInfo />
            <NavMenu />
            <div className="w-full max-w-[1024px] rounded bg-white p-6">
              <h1 className="text-4xl">Regulamento</h1>
              <p>Faça download do regulamento</p>
            </div>
          </div>
        </div>
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Regulamento - ACELERA BRB 2025</title>
      </Helmet>
      <div id="register-wrapper" className="min-h-screen flex flex-col md:flex-row 2xl:items-center md:justify-end">
        <div className="flex-1 flex items-center justify-center py-4 md:p-0 md:max-h-screen md:fixed md:top-0 md:left-[23vw] md:bottom-0">
          <img src={Logo} alt="Logo" className="h-24 md:h-[50vh]" />
        </div>

        <div className={whiteBox}>
          <div className="w-full max-w-[1024px] rounded bg-white p-6">
            <h1 className="text-4xl">Regulamento</h1>
            <p>Faça download do regulamento</p>
          </div>
        </div>
      </div>
    </>
  );
}

import { api } from "./api";

export async function getBanner() {
  const payload = [{ $sort: { created: -1 } }, { $limit: 1 }] as any;

  const { data } = await api.post(`database/banner_home__c/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}


export async function getAllBanners() {
  const payload = [{ $sort: { created: -1 } }] as any;

  const { data } = await api.post(`database/banner_home__c/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";

import "./styles.scss";
import { Link, NavLink } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import { getCampanha } from "services/campanha.service";
import { RegulamentoFooter } from "components/regulamento-footer";

import videoSec from "assets/img/video-icon-sec.png";

import bg1 from "assets/img/bg-cap-1.png";
import bg2 from "assets/img/bg-cap-2.png";
import bg3 from "assets/img/bg-cap-3.png";

import cd1 from "assets/img/bg-cad-1.png";
import cd2 from "assets/img/bg-cad-2.png";
import cd3 from "assets/img/bg-cad-3.png";
import cd4 from "assets/img/bg-cad-4.png";
import cd5 from "assets/img/bg-cad-5.png";

import { func } from "prop-types";
import Carousel from "react-multi-carousel";
import { VideoModal } from "components/modal/video";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const responsive2 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

// function openVideo(url:string){
//   console.log(url);
// }

export function Campanha() {
  const { user } = useAuth();
  const { showModal } = useModal();
  const [campanha, setCampanha] = useState<any>();

  setTimeout(() => {
    var btn = document.getElementById("video-modal") as HTMLElement;
    if (!!btn) {
      btn.onclick = function (e) {
        let obj = {
          link: btn.getAttribute("data-url"),
        };

        showModal("modal-video", {
          config: obj,
        });

        e.preventDefault();

        return false;
      };
    }
  }, 300);

  useEffect(() => {
    if (user) {
      // console.log(user.challenges);
      const completed = user.extra.avatar || user.image;
      if (!completed) {
        showModal("avatar-challenge");
      }

      getCampanha().then(function (data) {
        setCampanha(data[0]);
      });
    }
  }, [user]);

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>ACELERA BRB 2025</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div
          id="aCampanha"
          className="z-10 relative flex flex-col items-center space-y-0 pb-16 md:p-10 md:pb-16"
        >
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0 md:pt-10">
            <UserInfo />
          </div>
          <div className="w-full md:w-[1300px]">
            <div className="p-3 md:p-5">
              {/* {campanha && (
                <>
                  <h2 dangerouslySetInnerHTML={{ __html: campanha.title }}></h2>
                  <div
                    className="w-full"
                    dangerouslySetInnerHTML={{ __html: campanha.html }}
                  ></div>
                </>
              )} */}

              {!!campanha?.first_carousel && (
                <Carousel
                  className="w-full"
                  showDots={true}
                  responsive={responsive}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                  {campanha.first_carousel.map((item: any) => (
                    <div>
                      <img src={item.image} alt="img 1" />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
          </div>

          {!!campanha?.html && (
            <div
              className="page-content w-screen"
              dangerouslySetInnerHTML={{ __html: campanha.html }}
            ></div>
          )}

          {/* <div className="bg-[#133085] w-screen text-white md:-mx-10">
            <div className="md:w-[1200px] py-4 md:py-0 px-12 mx-auto flex flex-col md:flex-row">
              <div className="flex flex-col justify-center space-y-5 mr-16">
                <h2 className="text-[#88E1F2] text-4xl font-bold leading-none w-[400px] uppercase">
                  Prontos para conquistarmos muito mais?
                </h2>

                <p className="text-xl uppercase font-bold tracking-widest">
                  Campanha de Incentivo 2023
                </p>

                <p className="text-lg">
                  Os últimos quatro anos do BRB foram incríveis e alcançamos
                  índices de crescimento inéditos. Tudo isso, graças ao seu
                  empenho e compromisso.
                </p>

                <p className="text-lg">
                  Agora, é hora de alçarmos voos ainda mais ousados e batermos
                  um novo recorde: ser o melhor banco do Brasil até 2027.
                </p>
              </div>

              <div className="md:min-w-[476px] pb-10 relative">
                <img
                  src="https://s3.amazonaws.com/funifier/games/brb_v2_hml/images/643829ec5c284e3dd5439b8d_original_como-participar.png"
                  alt="Video Icon"
                />
              </div>
            </div>
          </div> */}

          <div className="bg-[#EBEBEB] w-screen">
            <div className="md:w-[1300px] mx-auto pt-20 pb-10 space-y-5">
              <h2 className="text-[#00ADEE] text-3xl font-bold uppercase text-center leading-none">
                <span className="text-[#133085]">prêmios</span> incríveis e{" "}
                <br />
                exclusivos <span className="text-[#133085]">para você!</span>
              </h2>

              <div className="w-full ">
                {campanha?.second_carousel && (
                  <Carousel
                    className=""
                    showDots={true}
                    responsive={responsive2}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                  >
                    {campanha.second_carousel.map((item: any) => (
                      <div className="flex justify-center">
                        <img src={item.image} alt={item.title} />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
          {!!campanha?.html_2 && (
            <div dangerouslySetInnerHTML={{ __html: campanha.html_2 }}></div>
          )}
        </div>
        {/* <RegulamentoFooter /> */}
      </div>
      <VideoModal />
      <Outlet />
    </>
  );
}

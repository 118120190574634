import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import ReactModal from "react-modal";

import badgeAnimation from "assets/lottie/trophy.json";
import partyAnimation from "assets/lottie/party.json";
import finishFlags from "assets/lottie/finish-flags.json";
import badge from "assets/lottie/badge.json";
import trophy from "assets/img/trophy.svg";
import congrats from "assets/img/achievement-title.png";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";
import { getNotificationsByLogin } from "services/notifications.service";
import { updateAchievement } from "services/achievement.service";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { api } from "services/api";

import "./styles.scss";

export function NotificationsModal() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { openModal, modalData, showModal } = useModal();
  const [notification, setNotification] = useState<any>();
  const [content, setContent] = useState<any>();

  const modalID = "notifications-modal";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal(changeRoute: boolean) {
    if (!notification.achievement.extra) {
      notification.achievement.extra = {};
    }
    if (!notification.achievement.extra.isOpen) {
      notification.achievement.extra.isOpen = [];
    }
    notification.achievement.extra.isOpen.push(user?._id);

    updateAchievement(notification.achievement);

    setIsOpen(false);
    if (changeRoute && location.pathname !== "extrato") {
      navigate("/extrato");
    } else {
      navigate(location.pathname);
    }
    setTimeout(() => {
      loadNotifications();
    }, 1500);
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  async function loadNotifications() {
    const data = await getNotificationsByLogin(user?._id);
    if (data !== undefined) {
      setNotification(data);

      loadAchievementByItemType(data);
      showModal("notifications-modal");
    }
  }

  async function loadAchievementByItemType(item: any) {
    if (!item) return;
    if (item.achievement && item.achievement.type === 0) {
      const data = await api.post(`/database/point_category/aggregate`, [
        { $match: { _id: item.achievement.item } },
      ]);
      setContent(data.data[0].category);
    }
    if (item.achievement && item.achievement.type === 1) {
      const data = await api.post(`/database/challenge/aggregate`, [
        { $match: { _id: item.achievement.item } },
      ]);
      setContent("Desafio " + data.data[0].challenge);
    }
    if (item.achievement && item.achievement.type === 2) {
      const data = await api.post(`/database/catalog_item/aggregate`, [
        { $match: { _id: item.achievement.item } },
      ]);
      setContent(data.data[0].name);
    }
  }

  if (!user) return null;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => handleCloseModal(false)}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="notificacoesModal">
            <div className="bg-white rounded-xl p-3 py-5 flex flex-col items-center flex-none mx-5 min-w-[315px] md:w-[400px]">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal(false)}
              >
                X
              </Button>
              <div className="text-center">
                <img
                  src={congrats}
                  alt="Parabéns"
                  className="max-w-[250px] md:max-w-auto"
                />
              </div>

              <div className="relative w-[250px] text-center">
                <h2 className="text-lg mt-10">
                  {notification && notification.source === "unidade"
                    ? "Sua Unidade"
                    : "Você"}{" "}
                  Conquistou
                </h2>
                {notification && (
                  <p>
                    <b>{notification.achievement.total.toLocaleString()}</b>{" "}
                    {content}
                  </p>
                )}
              </div>

              <div className="relative w-[300px]">
                <Lottie
                  animationData={partyAnimation}
                  loop={true}
                  style={{ width: 300, marginTop: "-70px" }}
                />
              </div>

              {/* <img src={achievementImage} alt="Parabéns" className="w-full" /> */}

              <div className="py-3 relative z-10 w-full flex flex-col space-y-2">
                <Button
                  className="bg-blue-light"
                  type="button"
                  onClick={() => handleCloseModal(true)}
                >
                  VER EXTRATO
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}

import { api } from "./api";

const tokenPublicStaging = 'Basic YnJiX3YyX2htbDo2M2YzOGQzOGI2OTIxZDQ5NjI0NTFjM2M=';
const tokenPublicProd = 'Basic YnJiOjYzZjM4ZDM4YjY5MjFkNDk2MjQ1MWMzYw==';

export async function getFaq() {
  const payload = [{ $sort: { order: 1} }] as any;

  const { data } = await api.post(`database/faq__c/aggregate`, payload, {
    headers: {
      Authorization: !!window.location.href.match(/(funifier\.app|localhost)/gi) ? tokenPublicStaging : tokenPublicProd,
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}
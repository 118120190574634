/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";
import { RegulamentoFooter } from "components/regulamento-footer";

import "./styles.scss";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  getAllChallenges,
  getChallenges,
  getChallengeById,
} from "services/challenges.service";
import moment from "moment";
import "moment/locale/pt-br";
import { getApuracao } from "services/extrato.service";
import ReactPaginate from "react-paginate";

moment.locale("pt-br");

export function ChallengesResults() {
  const { user } = useAuth();
  const { showModal } = useModal();
  const [challenge, setChallenge] = useState<any>();
  const [listEmpregados, setListEmpregados] = useState<any>();
  const [listUnidades, setListUnidades] = useState<any>();

  const [showList, setShowList] = useState("empregado");

  const [loading, setLoading] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();

  async function loadChallenges(id: any) {
    const data = await getChallengeById(id);
    const resp = await getApuracao(id);

    const updatedResp = resp.map(function (e: any) {
      if (e.rewards[0].player.type === 1) {
        e.type = "unidade";
      } else {
        e.type = "empregado";
      }
      return e;
    });

    const uni = updatedResp.filter((e: any) => e.type === "unidade")
      .sort((a: any, b: any) => {
        if (a.rewards[0].player.name > b.rewards[0].player.name) return 1;
        if (a.rewards[0].player.name < b.rewards[0].player.name) return -1;
        return 0;
      });

    const emp = updatedResp.filter((e: any) => e.type === "empregado")
      .sort((a: any, b: any) => {
        if (a.rewards[0].player.name > b.rewards[0].player.name) return 1;
        if (a.rewards[0].player.name < b.rewards[0].player.name) return -1;
        return 0;
      });


    setChallenge(data[0]);

    setListUnidades(uni);
    setListEmpregados(emp);

    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const id = searchParams.get("item");
    if (id && user) {
      loadChallenges(id);
    } else {
    }
  }, [user]);

  function Items({ currentItems }: any) {
    return (
      <>
        {currentItems && currentItems.length ? (
          <table>
            <thead>
              <tr>
                <th>NOME</th>
                <th>Qtd.</th>
                <th>PRÊMIO</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((item: any) =>
                item.rewards?.map((reward: any) => (
                  <tr key={reward._id}>
                    <td>{reward.player.name}</td>
                    <td>{reward.total}</td>
                    <td>{reward.name}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        ) : (
          <p className="text-4xl text-center">Sem nenhuma apuração</p>
        )}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }: any) {
    let list = [];
    if (showList === "unidade") {
      list = listUnidades;
    } else {
      list = listEmpregados;
    }
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = list.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(list.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event: any) => {
      const newOffset = (event.selected * itemsPerPage) % list.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel="Próxima >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< Aterior"
          renderOnZeroPageCount={null}
          className="pagination mt-6"
        />
      </>
    );
  };

  if (!user || loading) return null;

  return (
    <>
      <Helmet>
        <title>ACELERA BRB 2025</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen pb-16 md:pb-0">
        <div className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:p-10">
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>

          <div
            className="bg-white w-screen text-[#15437E] md:-mx-10 bgShadow"
            id="pageChallengesDetail"
          >
            <div className="w-full md:w-[1200px] mx-auto  p-5 md:p-8">
              <Link to="/desafios" className="mb-2 font-bold">
                &lt;- Voltar
              </Link>
              <h2>Apuração</h2>

              <div
                className="text-center space-y-4 mt-5"
                style={
                  {
                    "--axis-color": challenge?.eixo
                      ? challenge?.eixo.background
                      : "#FFF",
                    "--text-color": challenge?.eixo
                      ? challenge?.eixo?.color
                      : "#FFF",
                  } as React.CSSProperties
                }
              >
                <span className="block text-2xl">{challenge?.eixo?.title}</span>
                <strong className="block text-5xl text-[#15437E] max-w-[900px] mx-auto">
                  {challenge?.challenge}
                </strong>

                <div className="text-center">
                  <p className="uppercase text-2xl font-bold text-[#15437E]">
                    Resultados
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <button onClick={() => setShowList("empregado")} className={`text-center py-2 px-5 font-bold border-[#88e1f2] border-[1px] ${showList === 'empregado' ? 'bg-[#88e1f2] text=[#072e92]' : ''}`}>EMPREGADOS</button>
                <button onClick={() => setShowList("unidade")} className={`text-center py-2 px-5 font-bold border-[#88e1f2] border-[1px] ml-2 ${showList === 'unidade' ? 'bg-[#88e1f2] text=[#072e92] ' : ''}`}>UNIDADES</button>
              </div>

              <div className="mt-10">
                {((listEmpregados && listEmpregados.length > 0) || (listUnidades && listUnidades.length > 0)) && (
                  <PaginatedItems itemsPerPage={100} />
                )}
              </div>
            </div>
          </div>
        </div>
        <RegulamentoFooter />
      </div>
      <Outlet />
    </>
  );
}

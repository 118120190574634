import { api } from "./api";

export async function getCampanha() {
  const payload = [{ $sort: { created: -1 } }, { $limit: 2 }] as any;

  payload.unshift({
    $match: {
      active: true,
    },
  });

  const { data } = await api.post(`database/a_campanha__c/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

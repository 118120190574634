import { api } from "./api";

export async function getRegulamento() {
  const payload = [{ $sort: { created: -1 } }, { $limit: 1 }] as any;

  payload.unshift({
    $match: {
      active: true,
      type:'campanha'
    },
  });


  const { data } = await api.post(`database/regulamento__c/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}
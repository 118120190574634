import { UserAvatar } from "components/user-avatar";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { genConfig } from "funifier-nice-avatar";
import { useEffect, useState } from "react";
import { NotificationsPlayerModal } from "components/modal/notifications-player";
import { getContent } from "services/dicas.service";
import {
  getNotifications,
  clearNotifications,
} from "services/notifications.service";
import { getNomeUnidade, getPointsUnidade } from "services/profile.service";
import { NotificationsModal } from "components/notifications-modal";
import Tippy from "@tippyjs/react";

import Logo from "assets/img/LogoBranca.png";
import Juka from "assets/img/Juka.png";
import trophy from "assets/img/trophy-icon.svg";
import IconStar from "assets/img/star-icon.svg";
import IconCard from "assets/img/cards-icon.svg";
import Trophy from "assets/img/trophy.png";
import "./styles.scss";
import { BiLogOut, BiMenuAltRight, BiUser, BiCoin } from "react-icons/bi";
import { RiPencilFill } from "react-icons/ri";
import { Badge } from "flowbite-react";
import { abridgedControl } from "utils/helpers";
import { MobileMenu } from "components/mobilemenu";
import { Link, NavLink } from "react-router-dom";
import { func } from "prop-types";
import { AiOutlineMenu } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import brbIcon from "assets/img/favicon-16x16.png";
import { JukaModal } from "components/modal/dica-juka";
import { getAllDicas } from "services/dicas.service";
import { actionLog } from "services/actionlog.service";
import { getCardsId } from "services/store.service";
import { FaExternalLinkAlt } from "react-icons/fa";
import { ModalRoleta } from "components/modal/roleta";
import { getGlobalConfig } from "services/global.service";

import Roleta from "assets/img/roleta.png";
import { useTour } from '@reactour/tour';
import SurpriseBox from "components/surprise-box";

const defaultConfig = genConfig();

export function UserInfo() {
  const { user, signOut } = useAuth();
  const { showModal } = useModal();
  const [avatarConfig, setAvatarConfig] = useState(defaultConfig);
  const [avatarCompleted, setAvatarCompleted] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showJuka, setShowJuka] = useState(true);
  const [dica, setDica] = useState<any>();
  const [notifications, setNotifications] = useState<any>();
  const [nomeUnidade, setNomeUnidade] = useState<any>();
  const [pontosUnidade, setPointsUnidade] = useState<any>();
  const [dicas, setDicas] = useState<any>();
  const [totalCards, setTotalCards] = useState<number>(0);
  const [globalConfig, setGlobalConfig] = useState<any>();
  const { setIsOpen } = useTour();

  function handleAvatarClick() {
    showModal("avatar", { config: avatarConfig });
  }

  function handleNotificationsPlayerClick() {
    showModal("modal-notifications", { config: notifications });
  }

  function handleActionLog(data: any) {
    actionLog("interaction", data);
  }

  function handleModalJuka() {
    showModal("modal-juka");
  }

  function handleModalRoleta() {
    showModal("modal-roleta");
  }

  function handleLinkClass({ isActive }: any) {
    const baseClass = isActive
      ? "menuActive text-white text-center py-1 md:px-4 md:py-2"
      : "bg-opacity-30 text-white text-center py-1 md:px-4 md:py-2 ";
    return `${baseClass} hover:text-white no-underline`;
  }

  useEffect(() => {
    if (user) {
      setAvatarCompleted(!!user.extra.avatar || !!user.image);
    }
    loadAllDicas();
    loadData();
    loadIdsCars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function loadData() {
    const global = await getGlobalConfig("global");
    setGlobalConfig(global[0]);

    if (user && !user?.extra.tourRoullet && global[0] && global[0].rouletteEnabled) {
      setIsOpen(true);
    }

    const data = await getNotifications();
    setNotifications(data);

    // const data2 = await getContent();
    // setDica(data2[0]);

    const data3 = await getNomeUnidade(user?.extra.unidade);
    setNomeUnidade(data3[0].nome);

    // const data4 = await getPointsUnidade(user?.extra.unidade);
    // const pts = data4[0] ? data4[0].total : 0;
    // setPointsUnidade(pts);
  }

  async function loadAllDicas() {
    const data = await getAllDicas();
    setDicas(data);
  }

  async function loadIdsCars() {
    const data = await getCardsId();
    let count = 0;

    for (const itemId in user?.catalog_items) {
      if (user && data[0].ids.includes(itemId) && user?.catalog_items[itemId] > 0) {
        count = count + user?.catalog_items[itemId];
      }
    }
    setTotalCards(count);
  }

  function handleJukaClick() {
    setShowJuka(false);
  }

  function cardHtml() {
    return (
      <>
        <h1 className="font-bold mb-2">Cards</h1>
        <p>
          São elementos de premiação não monetários e que podem ser instantâneos
          ou colecionáveis e podem ser trocados por estrelas no mercado de
          cards.
        </p>
        <p>
          O CARD CURINGA aumenta suas chances e permite completar qualquer
          prêmio!
        </p>
        <p>O conteúdo dos cards é surpresa e pode carregar:</p>
        <ul className="list-disc p-5 ">
          <li>Prêmios físicos (prêmios BRB)</li>
          <li>Pontos no Mundo BRB (produtos)</li>
          <li>Experiências BRB (ingressos)</li>
          <li>Estrelas</li>
        </ul>
      </>
    );
  }

  function startHtml() {
    return (
      <>
        <h1 className="font-bold mb-2">Estrelas</h1>
        <p>
          São elementos de premiação não monetários e que podem ser acumuladas e
          trocadas por experiências exclusivas do BRB.
        </p>{" "}
        <ul className="list-disc p-5 ">
          <li>Ingressos de eventos patrocinados </li>
          <li>Experiências completas</li>
          <li>Gifts colecionáveis BRB </li>
          <li>Presentes exclusivos BRB </li>
          <li>Cards curinga</li>
          <li>E extras</li>
        </ul>
      </>
    );
  }

  function giroHtml() {
    return (
      <>
        <h1 className="font-bold mb-2">Roleta da Sorte</h1>
        <p>
          Essa nova ferramenta permitirá você testar a sua sorte para ganhar prêmios, mas atenção:
        </p>
        <ul className="list-disc p-5 ">
          <li>Cada roleta tem o prazo de 30 dias para expirar.</li>
          <li>Neste ícone, acompanhe quantos giros você tem na roleta.</li>
        </ul>
      </>
    );
  }

  function dicaJuka(dica: any) {
    return (
      <>
        <p dangerouslySetInnerHTML={{ __html: dica.content }}></p>
      </>
    );
  }

  function cutText(text: string) {
    return text.substring(0, 54) + "...";
  }

  if (!user) return null;

  return (
    <>
      <div className="w-full block ">
        <div className="md:hidden space-x-3 text-right pt-3 pr-3">
          {dica && showJuka && (
            <div className="juka-mobile">
              <div className={dica.content.length > 54 ? "showMore" : "flex"}>
                <p
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html:
                      dica.content.length > 54
                        ? cutText(dica.content)
                        : dica.content,
                  }}
                ></p>
                {dica.content.length > 54 && (
                  <Tippy
                    arrow={false}
                    className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                    allowHTML={true}
                    touch={"hold"}
                    content={dicaJuka(dica)}
                  >
                    <small>Ler Mais</small>
                  </Tippy>
                )}
              </div>
              <div className="imageJuka">
                <p>
                  <img src={Juka} alt="Juka" />
                  <span>#Dica do Juka</span>
                  {dicas.length > 1 && (
                    <button onClick={handleModalJuka}>Ver todas</button>
                  )}
                </p>
              </div>
            </div>
          )}

          <button className="ml-auto" onClick={() => setMenuOpen(true)}>
            <AiOutlineMenu color="white" size={30} />
          </button>
        </div>

        <div className="flex items-center justify-center w-full p-2 md:p-0">

          <div id="user-info" className="flex mt-5 md:mt-0">
            <div className="logo">
              <NavLink to="/inicio" className={handleLinkClass}>
                <img src={Logo} alt="Logo" className="h-[95px] md:h-36" />
              </NavLink>
            </div>

            <div className="menu">
              {/* {dica && showJuka && (
                <div className="juka">
                  <div className={dica.content.length > 54 ? "showMore" : "flex"}>
                    <p
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html:
                          dica.content.length > 54
                            ? cutText(dica.content)
                            : dica.content,
                      }}
                    ></p>
                    {dica.content.length > 54 && (
                      <Tippy
                        arrow={false}
                        className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                        allowHTML={true}
                        touch={"hold"}
                        content={dicaJuka(dica)}
                      >
                        <small>Ler Mais</small>
                      </Tippy>
                    )}
                  </div>
                  <div className="imageJuka">
                    <p>
                      <img src={Juka} alt="Juka" />
                      <span>#Dica do Juka</span>
                      {dicas.length > 1 && (
                        <button onClick={handleModalJuka}>Ver todas</button>
                      )}
                    </p>
                    <button className="close" onClick={() => handleJukaClick()}>
                      <IoMdClose size={20} color="white" />
                    </button>
                  </div>
                </div>
              )} */}
              {(!dica || !showJuka) && <p>&#160;</p>}

            </div>

            <div className="infosUser">
              <div className="w-full flex items-center">
                <div className="flex-1">
                  <h1 className="md:text-xl leading-none font-bold capitalize text-center">
                    {abridgedControl(user?.name, 15).toLowerCase()}
                  </h1>
                  {/* <div
                    className="flex items-center space-x-1 justify-center"
                    title="Pontos Mundo BRB"
                  >
                    <BiCoin size={20} />
                    <p>
                      {user?.point_categories?.brb_points
                        ? user?.point_categories?.brb_points.toLocaleString()
                        : 0}{" "}
                      pts
                    </p>
                  </div> */}
                  <div className="points">
                    <Tippy
                      arrow={false}
                      className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                      allowHTML={true}
                      content={startHtml()}
                    >
                      <div className="">
                        <img src={IconStar} alt="" />

                        <span className="bgPoint">
                          {user?.pointCategories?.star || 0}
                        </span>
                      </div>
                    </Tippy>

                    {/* <Tippy
                      arrow={false}
                      className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                      allowHTML={true}
                      content={cardHtml()}
                    >
                      <div>
                        <img src={IconCard} alt="" />
                        <span className="bgPoint">{totalCards}</span>
                      </div>
                    </Tippy> */}

                    {globalConfig && globalConfig.rouletteEnabled && (
                      <Tippy
                        arrow={false}
                        className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                        allowHTML={true}
                        content={giroHtml()}
                      >
                        <div data-tour="step-1">
                          <button onClick={handleModalRoleta}>
                            <img src={Roleta} alt="" />

                            <span className="bgPoint">
                              {user?.pointCategories?.giro || 0}
                            </span>
                          </button>
                        </div>
                      </Tippy>
                    )}
                  </div>
                </div>

                <div className="md:flex-1">
                  {notifications?.length > 0 && (
                    <button
                      className="notifications"
                      onClick={() => handleNotificationsPlayerClick()}
                    >
                      {notifications.length < 9 ? notifications.length : "9+"}
                    </button>
                  )}
                  <div
                    id="user-avatar"
                    className="image"
                    onClick={() => handleAvatarClick()}
                  >
                    <UserAvatar
                      data={{ ...user, ...user?.extra }}
                      responsive={true}
                    />
                    <button className="editButton">
                      <RiPencilFill size={20} />
                      Editar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="w-full flex md:hidden md:items-center unidade-user-info px-3 mt-8">
          <p>
            <strong>{nomeUnidade ? nomeUnidade : "----"}</strong>
          </p>

          {/* <Link className="points" to="/extrato?unidade=true">
            <p className="w-full uppercase w-[100%] text-[0.5em]">
              <img className="h-5" src={trophy} alt="Parabéns" />{" "}
              <strong>{pontosUnidade ? pontosUnidade : 0}</strong> pontos mundo
              BRB
            </p>
          </Link> */}
          <Link
            className="points"
            to="https://mundo.brb.com.br/home"
            target="_blank"
          >
            <p className="w-full ">
              <FaExternalLinkAlt /> Mundo BRB
            </p>
          </Link>
        </div>

        <div className="navlink">
          <NavLink
            to="/inicio"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "inicio",
                itemTitle: "INÍCIO",
                type: "page_view",
              })
            }
          >
            INÍCIO
          </NavLink>
          {/* <NavLink
            to="/objetivos"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "eixos",
                itemTitle: "EIXOS",
                type: "page_view_custom",
              })
            }
          >
            EIXOS
          </NavLink> */}
          <NavLink
            to="/desafios"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "desafios",
                itemTitle: "DESAFIOS",
                type: "page_view",
              })
            }
          >
            DESAFIOS
          </NavLink>
          {/* <NavLink
            to="/reta-final"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "retaFinal",
                itemTitle: "RETA FINAL",
                type: "page_view_custom",
              })
            }
          >
            RETA FINAL
          </NavLink> */}
          <NavLink
            to="/como-ganhar"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "formasDeGanhar",
                itemTitle: "FORMAS DE GANHAR",
                type: "page_view_custom",
              })
            }
          >
            FORMAS DE GANHAR
          </NavLink>
          {/* <NavLink
            to="/quadro-medalhas"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "quadroMedalhas",
                itemTitle: "QUADRO DE MEDALHAS",
                type: "page_view_custom",
              })
            }
          >
            QUADRO DE MEDALHAS
          </NavLink> */}
          {/* <NavLink
            to="/recordistas"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "recordistas",
                itemTitle: "RECORDISTAS",
                type: "page_view_custom",
              })
            }
          >
            RECORDISTAS
          </NavLink> */}
          {/* <NavLink
            to="/premios"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "meusCards",
                itemTitle: "MEUS CARDS",
                type: "page_view",
              })
            }
          >
            CARDS 2023
          </NavLink> */}
          <NavLink
            to="/experiencias"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "mercadoDeEstrelas",
                itemTitle: "MERCADO DE ESTRELAS",
                type: "page_view",
              })
            }
          >
            MERCADO DE ESTRELAS
          </NavLink>
          {/* <NavLink
            to="/aniversario"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "aniversarioBrb58",
                itemTitle: "ANIVERSÁRIO 58 ANOS",
                type: "page_view",
              })
            }
          >
            ANIVERSÁRIO 58 ANOS
          </NavLink> */}
          {/* <NavLink
            to="/convencao"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "convencao",
                itemTitle: "CONVENÇÃO",
                type: "page_view_custom",
              })
            }
          >
            CONVENÇÃO
          </NavLink> */}
        </div>




        {menuOpen ? <MobileMenu onClose={() => setMenuOpen(false)} /> : null}
        <NotificationsPlayerModal />
        <JukaModal />
      </div>
      <ModalRoleta />
      <NotificationsModal />
      {globalConfig && globalConfig.surpriseEnabled && (
        <SurpriseBox />
      )}
    </>
  );
}

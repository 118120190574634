import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { actionLog } from "services/actionlog.service";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";

import "./styles.scss";

export function ChatModal() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  const modalID = "modal-chat";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  function handleShowAvatar() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalChat">
            <div className="bg-white rounded-xl p-3 flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              <h3 className="text-lg text-blue-500 font-bold">Chat Campanha</h3>{" "}
              <br />
              <div className="md:w-[1000px] flex flex-col md:flex-row space-y-16 md:space-x-6 md:space-y-0 md:items-start md:justify-center">
                <div className="flex-1 flex flex-col">
                  <div className="rounded overflow-hidden shadow-xl h-[210px] md:h-[560px]">
                    CHAT AQUI
                  </div>
                </div>
              </div>
              <div className="py-3 relative z-10 w-full flex flex-col space-y-2"></div>
            </div>
          </div>
        )}
      />
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";

import "./styles.scss";
import { NavLink } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import { RegulamentoFooter } from "components/regulamento-footer";
import { FaStar } from "react-icons/fa";
import { getVirtualGoods, purchaseItem, getTransparencia } from "services/store.service";
import { toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import { Button, Modal } from "flowbite-react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Scrollbars } from "react-custom-scrollbars-2";

import noImage from "assets/img/semImagem.jpg";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "components/custom-captcha";
import { IoReload } from "react-icons/io5";
import moment from "moment";

export function Experiencias() {
  const { refreshUser, user } = useAuth();
  const { showModal } = useModal();
  const [cards, setCards] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<string | undefined>();
  const props = { openModal, setOpenModal };
  const [item, setItem] = useState<any>([]);
  const [orderBy, setOrderBy] = useState("creationDate");
  const [headerProdutos, setHeaderProdutos] = useState<boolean>(false);
  const [headerExpriencias, setHeaderExperiencia] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [transparencia, setTransparencia] = useState<any>([]);
  const [showCaptcha, setShowCaptcha] = useState<boolean>(true);

  async function loadItems() {
    const data = await getVirtualGoods();
    setCards(
      data.filter((item: any) => {
        if (
          item.catalogId === "experiencias" &&
          item.active === true &&
          item.extra.experiencia
        ) {
          setHeaderExperiencia(true);
        }
        if (
          item.catalogId === "experiencias" &&
          item.active === true &&
          !item.extra.experiencia
        ) {
          setHeaderProdutos(true);
        }
        return item.catalogId === "experiencias" && item.active === true;
      })
    );
    setLoading(false);
  }

  async function loadTransparencia() {
    const data = await getTransparencia();
    setTransparencia(data[0]);
  }

  useEffect(() => {
    setLoading(true);

    loadItems();
    loadTransparencia();
  }, [user]);

  function purchase(item: any) {
    if (!!user?._id) {
      purchaseItem(item._id, user._id).then(function (data) {
        if (data.status === "OK") {
          toast(
            `👍 sua experiência/produto foi adquirida. A GEMAC entrará em contato com você em até 48h para agendar a entrega.`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );

          refreshUser();
        } else if (data.status === "UNAUTHORIZED") {
          if (data.restrictions.indexOf("insufficient_requirements") !== -1) {
            showMessageError("Estrelas insuficientes para realizar o resgate.");
          } else if (
            data.restrictions.indexOf("exceeded_purchase_limit") !== -1
          ) {
            showMessageError(
              "Limite de resgate da experiência/produto atingido."
            );
          } else if (data.restrictions.indexOf("item_does_not_exist") !== -1) {
            showMessageError("O item não existe mais.");
          } else if (
            data.restrictions.indexOf("player_does_not_exist") !== -1
          ) {
            showMessageError("O player informado para o resgate não existe.");
          } else if (data.restrictions.indexOf("item_disabled") !== -1) {
            showMessageError("Este item está desabilitado");
          } else if (data.restrictions.indexOf("item_out_of_time") !== -1) {
            showMessageError(
              "Ultrapassado o período para resgate da experiência/produto."
            );
          } else if (data.restrictions.indexOf("limit_exceeded") !== -1) {
            showMessageError(
              "Você já reaizou o resgate desta experiência/produto."
            );
          } else if (data.restrictions.indexOf("item_sold_out") !== -1) {
            showMessageError("Este item está esgotado.");
          } else if (
            data.restrictions.indexOf("principal_not_allowed") !== -1
          ) {
            showMessageError("Você não tem permissão para realizar o resgate.");
          } else if (
            data.restrictions.indexOf("inactive_user_not_allowed") !== -1
          ) {
            showMessageError("Seu usuário está inativo e não pode realizar o resgate.");
          }
        } else {
          toast(`❌ Ocorreu um erro, tente novamente mais tarde.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setShowCaptcha(true);
      });
      props.setOpenModal(undefined);
    }
  }

  function showMessageError(text: string) {
    toast.error(`${text}`, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  function isItemLocked(item: any) {
    var ok = 0;
    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        if (o.type === 0) {
          if (o.total === 0) {
            ok = ok + 1;
          } else if (
            user?.point_categories[o.item] &&
            user?.point_categories[o.item] >= o?.total
          ) {
            ok = ok + 1;
          }
        } else if (
          o.type === 1 &&
          user?.challenges[o.item] &&
          user?.challenges[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 2 &&
          user?.catalog_items[o.item] &&
          user?.catalog_items[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (o.type >= 3) {
          //ignorar qualquer outro tipo como level, crowning etc.
          ok = ok + 1;
        }
      });
    } else if (!item.requires) {
      ok = ok + 1;
    }

    return !(ok === item.requires.length);
  }

  function isItemLockedByDate(item: any) {
    var locked = null;
    if (item && item.requires) {
      if(item.start){
        if (item.start > new Date().getTime()) {
          locked = "start";
        }
      }  
      if(item.end){
        if (item.end < new Date().getTime()) {
          locked = "end";
        }
      }   
    }
    return locked;
  }

  const handleChange = (event: any) => {
    setOrderBy(event.target.value);
  };

  const sortedCards = [...cards];

  if (orderBy === "alphabetical") {
    sortedCards.sort((a, b) => a.name.localeCompare(b.name));
  } else if (orderBy === "priceUp") {
    sortedCards.sort((a, b) => a.requires[0].total - b.requires[0].total);
  } else if (orderBy === "priceDown") {
    sortedCards.sort((a, b) => b.requires[0].total - a.requires[0].total);
  } else if (orderBy === "creationDate") {
    sortedCards.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    );
  }

  function doSubmit() {
    const userCaptchaElement = document.getElementById(
      "user_captcha_input"
    ) as HTMLInputElement;

    if (userCaptchaElement) {
      const value = userCaptchaElement.value;
      if (validateCaptcha(value)) {
        setShowCaptcha(false);
        alert("Captcha correspondente");
      } else {
        alert("Captcha não corresponde, tente novamente.");
      }
    }
  }

  const downloadDocument = () => {
    window.open(transparencia.document.url);
  };

  const toggleRegulation = (e: any) => {
    const element = document.getElementById("transparenciaFooter");

    if (!element) return;
    if (element.classList.contains("close")) {
      element.classList.remove("close");
      element.classList.add("open");
      setShow(true);
    } else {
      element.classList.remove("open");
      element.classList.add("close");
      setShow(false);
    }
  };

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>ACELERA BRB 2025</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div
          className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:p-10"
          id="experiencias"
        >
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>
          <div className="bg-[#0938A3] w-screen text-white md:-mx-10 bgShadow">
            <div className="w-full md:w-[1200px] mx-auto p-5 md:p-8">
              <h2>
                Confira as <strong>Opções disponíveis</strong>
              </h2>
              <p>
                Você possui{" "}
                <strong>{user?.pointCategories?.star || 0} estrelas</strong>.
                Troque suas estrelas pelo que preferir entre as opções abaixo.
              </p>
            </div>
          </div>
          <div className="bg-[#F2F2F2] w-screen text-white md:-mx-10 sectionExp">
            <div className="w-full md:w-[1200px] mx-auto  p-5 md:p-8">
              <div className="flex justify-end items-center mb-4">
                <span className="text-[#000]">Filtrar por: </span>
                <select
                  value={orderBy}
                  onChange={handleChange}
                  className="text-[#000] ml-2"
                >
                  <option value="">Selecione uma opção</option>
                  <option value="creationDate">Mais recentes</option>
                  <option value="priceDown">Maior preço</option>
                  <option value="priceUp">Menor preço</option>
                  <option value="alphabetical">Ordem alfabética</option>
                </select>
              </div>
              {loading && <p className="text-center loading">CARREGANDO...</p>}
              {headerProdutos && (
                <h3 className="font-bold border-b-2 mb-4 text-[#88e1f2] uppercase text-[1.8em]">
                  Produtos
                </h3>
              )}
              <ul className="listItems grid lg:grid-cols-4">
                {!loading &&
                  sortedCards
                    .filter((e: any) => {
                      return !e.extra.experiencia;
                    })
                    .map((item: any, i: number) => (
                      <li
                        className={`${
                          item.amount !== -1 &&
                          item.amount <= item.owned &&
                          "opacity-50"
                        }`}
                      >
                        <div className="image">
                          <img
                            src={item.image ? item.image.medium.url : noImage}
                            alt={item.name}
                            title={item.name}
                          />
                        </div>
                        <div className="content">
                          <span className="qtdStar">
                            {item.requires[0].total} <FaStar size={18} />
                          </span>
                          <h3>{item.name}</h3>
                          <p>{item.description}</p>

                          {item.amount === -1 || item.amount > item.owned ? (
                            <>
                              {!isItemLockedByDate(item) ? (
                                <Tippy
                                  arrow={false}
                                  className={`${
                                    !isItemLocked(item) && "hidden"
                                  } bg-blue-light drop-shadow-lg p-4`}
                                  content="Você não tem estrelas suficientes"
                                >
                                  <div>
                                    <button
                                      disabled={isItemLocked(item)}
                                      className={`${
                                        isItemLocked(item) && "opacity-20"
                                      }`}
                                      onClick={() => {
                                        loadCaptchaEnginge(6, "darkgrey", "white", "", 10, true);
                                        props.setOpenModal("pop-up");
                                        setItem(item);
                                      }}
                                    >
                                      RESGATAR -&gt;
                                    </button>
                                  </div>
                                </Tippy>
                              ) : (
                                <>
                                  {isItemLockedByDate(item) === 'start' && (
                                    <Tippy
                                        arrow={false}
                                        className={` bg-blue-light drop-shadow-lg p-4`}
                                        content={`O Item ainda não esta liberado para compra somente em ${moment(item.start).format("DD/MM/yyyy HH:mm")}`}
                                      >
                                      <div>
                                        <button
                                          disabled={true}
                                          className="opacity-20"
                                          
                                        >
                                          RESGATAR -&gt;
                                        </button>
                                        <div className="text-center text-[0.9em]">Libera em: {moment(item.start).format("DD/MM/yyyy HH:mm")}</div>
                                      </div>
                                    </Tippy>
                                  )}
                                  {isItemLockedByDate(item) === 'end' && (
                                    <Tippy
                                        arrow={false}
                                        className={` bg-blue-light drop-shadow-lg p-4`}
                                        content={`O Item estava liberado para compra até ${moment(item.end).format("DD/MM/yyyy HH:mm")}`}
                                      >
                                      <div>
                                        <button
                                          disabled={true}
                                          className="opacity-20"
                                          
                                        >
                                          RESGATAR -&gt;
                                        </button>
                                        <div className="text-center text-[0.9em]">Encerrado em: {moment(item.start).format("DD/MM/yyyy HH:mm")}</div>
                                      </div>
                                    </Tippy>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <Tippy
                              arrow={false}
                              className="bg-blue-light drop-shadow-lg p-4"
                              content="O item encontra-se indisponível para compra"
                            >
                              <div>
                                <button className="opacity-50 cursor-default">
                                  ESGOTADO
                                </button>
                              </div>
                            </Tippy>
                          )}

                          <small>
                            {item.extra && item.extra.text
                              ? item.extra.text
                              : ""}
                          </small>
                        </div>
                      </li>
                    ))}
              </ul>
              {headerExpriencias && (
                <h3 className="font-bold border-b-2 mb-4 text-[#88e1f2] uppercase text-[1.8em]">
                  Experiências
                </h3>
              )}
              <ul className="listItems grid lg:grid-cols-4">
                {!loading &&
                  sortedCards
                    .filter((e: any) => {
                      return !!e.extra.experiencia;
                    })
                    .map((item: any, i: number) => (
                      <li
                        className={`${
                          item.amount !== -1 &&
                          item.amount <= item.owned &&
                          "opacity-50"
                        }`}
                      >
                        <div className="image">
                          <img
                            src={item.image ? item.image.medium.url : noImage}
                            alt={item.name}
                            title={item.name}
                          />
                        </div>
                        <div className="content">
                          <span className="qtdStar">
                            {item.requires[0].total} <FaStar size={18} />
                          </span>
                          <h3>{item.name}</h3>
                          <p>{item.description}</p>

                          {item.amount === -1 || item.amount > item.owned ? (
                            <>
                              {!isItemLockedByDate(item) ? (
                                <Tippy
                                arrow={false}
                                className={`${
                                  !isItemLocked(item) && "hidden"
                                } bg-blue-light drop-shadow-lg p-4`}
                                content="Você não tem estrelas suficientes"
                              >
                                <div>
                                  <button
                                    disabled={isItemLocked(item)}
                                    className={`${
                                      isItemLocked(item) && "opacity-20"
                                    }`}
                                    onClick={() => {
                                      loadCaptchaEnginge(6, "darkgrey", "white", "", 10, true);
                                      props.setOpenModal("pop-up");
                                      setItem(item);
                                    }}
                                  >
                                    RESGATAR -&gt;
                                  </button>
                                </div>
                              </Tippy>
                              ) : (
                                <>
                                  {isItemLockedByDate(item) === 'start' && (
                                    <Tippy
                                        arrow={false}
                                        className={` bg-blue-light drop-shadow-lg p-4`}
                                        content={`O Item ainda não esta liberado para compra somente em ${moment(item.start).format("DD/MM/yyyy HH:mm")}`}
                                      >
                                      <div>
                                        <button
                                          disabled={true}
                                          className="opacity-20"
                                          
                                        >
                                          RESGATAR -&gt;
                                        </button>
                                        <div className="text-center text-[0.9em]">Libera em: {moment(item.start).format("DD/MM/yyyy HH:mm")}</div>
                                      </div>
                                    </Tippy>
                                  )}
                                  {isItemLockedByDate(item) === 'end' && (
                                    <Tippy
                                        arrow={false}
                                        className={` bg-blue-light drop-shadow-lg p-4`}
                                        content={`O Item estava liberado para compra até ${moment(item.end).format("DD/MM/yyyy HH:mm")}`}
                                      >
                                      <div>
                                        <button
                                          disabled={true}
                                          className="opacity-20"
                                          
                                        >
                                          RESGATAR -&gt;
                                        </button>
                                        <div className="text-center text-[0.9em]">Encerrado em: {moment(item.start).format("DD/MM/yyyy HH:mm")}</div>
                                      </div>
                                    </Tippy>
                                  )}
                                </>
                              )}
                            </>
                            
                          ) : (
                            <Tippy
                              arrow={false}
                              className="bg-blue-light drop-shadow-lg p-4"
                              content="O item encontra-se indisponível para compra"
                            >
                              <div>
                                <button className="opacity-50 cursor-default">
                                  ESGOTADO
                                </button>
                              </div>
                            </Tippy>
                          )}

                          <small>
                            {item.extra && item.extra.text
                              ? item.extra.text
                              : ""}
                          </small>
                        </div>
                      </li>
                    ))}
              </ul>
              <Modal
                show={props.openModal === "pop-up"}
                size="md"
                popup
                onClose={() => {props.setOpenModal(undefined);setShowCaptcha(true);}}
              >
                <Modal.Header />
                <Modal.Body>
                  <div className="text-center">
                    <AiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      <span className="text-[red]">ATENÇÃO:</span> não
                      realizamos reembolso de estrelas ou troca/devolução de
                      produtos. Antes de confirmar um resgate verifique todas as
                      informações sobre o produto ou experiência.
                    </h3>
                    {!showCaptcha && (
                      <>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Você confirma o resgate dessa experiência/produto?
                        </h3>
                        <div className="flex justify-center gap-4">
                          <Button
                            color="gray"
                            onClick={() => {
                              props.setOpenModal(undefined);
                              setShowCaptcha(true);
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button onClick={() => purchase(item)}>
                            Confirmar
                          </Button>
                        </div>
                      </>
                    )}
                    {!!showCaptcha && (
                      <>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Informe o código abaixo para resgate dessa
                          experiência/produto.
                        </h3>
                        <div className="text-center">
                          <LoadCanvasTemplate reloadText={<IoReload />} reloadColor={"black"}/>
                          <small>O captcha diferencia letras maiúsculas e minúsculas</small>
                          <input
                            type="text"
                            id="user_captcha_input"
                            className="m-2"
                          />
                          <Button className="mx-auto" onClick={doSubmit}>
                            Validar
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
              {!loading && cards?.length === 0 ? (
                <p className="text-center loading text-[#09236b]">
                  Em breve você poderá consultar aqui, as estrelas que
                  conquistou e as experiências disponíveis para resgate.
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="text-center">
          <div className="">
            <div>
              <button
                className="bg-[#88E1F2] text-[#04227C] w-full py-4 font-bold uppercase"
                onClick={(e) => {
                  toggleRegulation(e);
                }}
              >
                TRANSPARÊNCIA
              </button>
            </div>
          </div>
        </div>

        <div id="transparenciaFooter" className="close">

          <div className="content">
            <div>
              <button
                type="button"
                className="closeRegulation"
                onClick={(e) => {
                  toggleRegulation(e);
                }}
              >
                X
              </button>

              <h2>TRANSPARÊNCIA</h2>

              <Scrollbars
                style={{ width: "100%" }}
                className="contentExtrato"
                renderTrackVertical={(props) => (
                  <div {...props} className="track-vertical" />
                )}
              >
                {transparencia && !!transparencia.title && (
                  <h3>{transparencia.title}</h3>
                )}

                {transparencia && !!transparencia.description && (
                  <p>{transparencia.description}</p>
                )}
                
                {transparencia && !!transparencia.image && transparencia.image.original && (
                  <img src={transparencia.image.original.url} alt="" />
                )}
              </Scrollbars>
            </div>

            <button className="download" onClick={downloadDocument}>
              Relatório Analítico de Resgates <i className="gg-software-download"></i>
            </button>
          </div>
        </div>
        
        <RegulamentoFooter />
      </div>

      <Outlet />
    </>
  );
}

import { useAuth } from "context/AuthContext";
import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {
  executeMystery,
  getAllRoletas,
  updateUnlockMystery,
  verifyUnlockMystery,
} from "services/mystery.service";
import { getGlobalConfig } from "services/global.service";
import { toast } from "react-toastify";

import congratAnimation from "assets/lottie/congratulations.json";
import sad from "assets/lottie/sad.json";
import giftBoxAnimation2 from "assets/lottie/gift-box-2.json";
import giftBoxAnimation from "assets/lottie/gift-box.json";
import "./styles.scss";
import TabFocusTrap from "components/tab-focus-trap";

const SurpriseBox = () => {
  Modal.setAppElement("#modal");

  const modalContent = useRef<HTMLDivElement>(null);
  const refEl = useRef(null);
  const { user, refreshUser } = useAuth();
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [showMystery, setShowMystery] = useState<boolean>(false);
  const [countdown, setCountdown] = useState();
  const [totalCoins, setTotalCoins] = useState<number>(0);
  const [showCongrats, setShowCongrats] = useState<boolean>(false);
  const [idMystery, setIdMystery] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  function openModal() {
    loadGlobalInfo();
  }

  function afterOpenModal() {}

  async function loadGlobalInfo() {
    const global = await getGlobalConfig("global");
    if (global[0].surpriseEnabled === false) {
      toast.error(`❌ Caixa surpresa desabilitada temporariamente.`, {
        theme: "colored",
      });
      closeModal();
    } else {
      setIsOpen(true);
      updateVerify();
      executeMystery(idMystery, user?._id).then((resp) => {
        if (resp.achievements.length > 0) {
          const a = resp.achievements.filter((e: any) => e.type === 0)[0];
          setTotalCoins(a.total);
          setTimeout(() => {
            if (!!modalContent && !!modalContent.current) {
              modalContent.current.querySelector("h2")?.focus();
            }
            setShowCongrats(true);
          }, 2300);
        } else {
          setTimeout(() => {
            if (!!modalContent && !!modalContent.current) {
              modalContent.current.querySelector("h2")?.focus();
            }
            setShowCongrats(true);
          }, 2300);
        }
      });
    }
  }

  function onFocus() {
    setTimeout(() => {
      modalContent.current?.querySelector(`h2`)?.focus();
    }, 300);
  }

  function closeModal() {
    refreshUser();
    setIsOpen(false);
    setShowMystery(false);
    loadVerifyUnlockMystery(user ? user._id : "");
  }

  useEffect(() => {
    if (!user) return;
    loadData();
    loadVerifyUnlockMystery(user._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, countdown]);

  async function loadData() {
    const data = await getAllRoletas();
    if (data.length > 0) {
      const surpriseBox = data.filter(
        (item) => item.active && item.extra.type === "surpriseBox"
      )[0];
      setIdMystery(surpriseBox._id);
    }
  }

  function loadVerifyUnlockMystery(id: string) {
    setLoading(true);
    verifyUnlockMystery(id)
      .then((resp) => {
        if (resp.length === 0) {
          setShowMystery(true);
        } else if (resp.length > 0) {
          if (hasPassed24Hours(resp[0].nextOpen)) {
            setShowMystery(true);
          }
          setCountdown(resp[0].nextOpen + 24 * 60 * 60 * 1000);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  }

  const hasPassed24Hours = (timestamp: any) => {
    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000; // 24 horas em milissegundos
    const currentTime = new Date().getTime(); // Tempo atual em milissegundos

    return currentTime - timestamp > twentyFourHoursInMilliseconds;
  };

  function updateVerify() {
    const obj = {
      _id: user?._id + "321582548",
      player: user?._id,
      nextOpen: { $date: new Date().getTime() },
    };
    updateUnlockMystery(obj);
  }

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  };

  return (
    <>
      <div className="mysteryBox">
        {showMystery && !loading ? (
          <button
            onClick={openModal}
            aria-label="Resgatar meu presente"
            tabIndex={0}
          >
            <Lottie animationData={giftBoxAnimation} loop={true} />
          </button>
        ) : (
          <>
            {!loading && (
              <div
                tabIndex={0}
                aria-label="Você poderá abrir o seu novo presente em "
              >
                <p>Próximo presente em:</p>
                {!!countdown && (
                  <Countdown date={countdown} renderer={renderer} />
                )}
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        portalClassName="relative z-[600]"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div className="modalDetailBox " ref={modalContent}>
            <TabFocusTrap isOpen={modalIsOpen} setIsOpen={openModal}>
              {!!showCongrats && totalCoins > 0 && (
                <div className="text-center text-[2em] win">
                  <Lottie
                    animationData={congratAnimation}
                    loop={true}
                    className="-mt-[100px]"
                    onLoopComplete={(e) => {
                      if (!!modalContent && !!modalContent.current) {
                        modalContent.current.querySelector("h2")?.focus();
                      }
                    }}
                  />
                  <h2 tabIndex={0} ref={refEl}>
                    Parabéns!!
                  </h2>
                  <p tabIndex={0}>
                    Você acabou de ganhar {totalCoins}
                    {totalCoins > 1 ? " moedas" : " moeda"}
                  </p>
                </div>
              )}

              {!!showCongrats && totalCoins === 0 && (
                <div className="text-center text-[2em] lose">
                  <Lottie
                    animationData={sad}
                    loop={true}
                    className="-mt-[140px]"
                    onLoopComplete={(e) => {
                      if (!!modalContent && !!modalContent.current) {
                        modalContent.current.querySelector("h2")?.focus();
                      }
                    }}
                  />
                  <h2 tabIndex={0} ref={refEl}>
                    Ahhh =(
                  </h2>
                  <p tabIndex={0}>Não foi desta vez.</p>
                  <p tabIndex={0}>Volte amanhã e tente novamente ;)</p>
                </div>
              )}

              {!showCongrats && (
                <Lottie
                  animationData={giftBoxAnimation2}
                  loop={false}
                  onAnimationEnd={onFocus}
                />
              )}

              <button
                onClick={closeModal}
                aria-label="Fechar modal de presente"
                tabIndex={0}
                className="flex border py-2 px-4 mx-auto rounded-full my-4 text-[1.5em] text-white"
              >
                X
              </button>
            </TabFocusTrap>
          </div>
        )}
      />
    </>
  );
};

export default SurpriseBox;
